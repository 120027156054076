.errorcontainer{
    width: 100%;
    height: 90vh;
    background-color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.errorcontainer h1{
    font-size: xx-large;
    color: red;
    align-self: center;
}
.errorcontainer p{
    color: #395ac9;
    font-size: 20px;
    text-align: center;
    padding: 5px;
}