.servicecontainer{
      padding-top: 85px;
      text-align: center;
}
.servicecontainer h1{
    color: #395ac9;
}
.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

.service {
  background-color: #fff;
  border-radius: 4px;
     box-shadow: 0px 4px 8px 0px #1100ff;
 transition: 0.3s;
   margin-bottom: 2rem;
  padding: 2rem;
  width: 30%;
}

.service__name {
  color: #1100ff;
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 0;
}

.service__description {
  color: #293241;
  margin-bottom: 1rem;
}

.service__stack {
  list-style: none;
  margin: 0;
  padding: 0;
}

.service__stack li {
  color: #293241;
  margin-bottom: 0.5rem;
}

.service__cta {
  background-color: #1100ff;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.75rem 1rem;
  transition: background-color 0.2s ease-in-out;
}

.service__cta:hover {
  background-color: #1100ff;
}

.service__cta:focus {
  outline: none;
  box-shadow: 0 0 0 2px #1100ff;
}

@media (max-width: 768px) {
  .services {
    justify-content: center;
  }

  .service {
    width: 100%;
  }
}
