/* General container styles */
.ourprocess-container {
  font-family: Arial, sans-serif;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 20px;
  box-sizing: border-box;
}

/* Title container */
.ourprocess-title-container {
  text-align: left;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
}

.ourprocess-title-container h1 {
  font-size: 4rem;
  margin-bottom: 10px;
  font-weight: 700;
  color: #333;
}

.bluedot {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #007BFF;
  border-radius: 50%;
}

.blueUnderline {
  width: 100px;
  height: 5px;
  background-color: #007BFF;
  margin-bottom: 20px;
}

/* Paragraph style */
.ourprocess-paragrah p, .process-content-item-header p {
  font-size: 1.2rem;
  color: #555;
  margin: 0;
  line-height: 1.6;
  max-width: 600px;
}

/* Content container */
.ourprocess-content-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  gap: 20px;
}

/* Process item styles */
.process-content-item {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  width: calc(50% - 20px);  /* Two columns layout */
}

.process-content-item .icon {
  font-size: 30px;
  color: #007BFF;
  flex-shrink: 0;
}

.process-content-item-header {
  width: 100%;
  padding-left: 20px;
}

.process-content-item h2 {
  font-size: 1.5rem;
  color: #333;
  margin: 0;
  font-weight: 600;
}

.process-content-item p {
  margin-top: 8px;
  color: #555;
  font-size: 1rem;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .ourprocess-title-container h1 {
    font-size: 2.5rem;
  }
  
  .process-content-item {
    width: 100%;  /* Full width for mobile */
    margin-top: 10px;
  }

  .process-content-item h2 {
    font-size: 1.3rem;
  }

  .process-content-item p {
    font-size: 0.95rem;
  }

.bluedot {
  width: 10px;
  height: 10px;
}
  
}

@media (max-width: 480px) {
  .ourprocess-title-container h1 {
    font-size: 2rem;
  }

  .blueUnderline {
    width: 80px;
    height: 4px;
  }

  .process-content-item-header {
    padding-left: 10px;
  }

  .process-content-item h2 {
    font-size: 1.2rem;
  }

  .process-content-item p {
    font-size: 0.9rem;
  }

  .process-content-item .icon {
    font-size: 24px;
  }
}
