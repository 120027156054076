/* General form styling */
.contact-form {
  max-width: 600px;
  background-color: #fff;
  padding: 30px;
  margin: 50px auto;
  border-radius: 10px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
  color: #333;
  position: relative;
}

/* Cancel button as an X icon */
.cancel-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: #007bff;
  font-size: 24px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.cancel-button:hover {
  color: #0056b3;
}

/* Form title */
.form-title-container {
  text-align: center;
  margin-bottom: 30px;
}

h3 {
  color: #2c3e50;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
}

.bluedot {
  color: #007bff;
  width: 10px;
  height: 10px;
}

.blue-underline {
  width: 70px;
  height: 6px;
  background-color: #007bff;
  margin: 0 auto;
}

/* Form labels for inputs */
.form-group label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #444;
  margin-bottom: 5px;
  text-align: left;
}

/* Form inputs */
.form-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.input-field, .textarea-field {
  width: 100%;
  padding: 12px 15px;
  font-size: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
  background-color: #f9f9f9;
}

.input-field:focus, .textarea-field:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Textarea */
.textarea-field {
  height: 120px;
  resize: vertical;
}

/* Checkbox section */
.checkbox-section {
  margin: 20px 0;
  color: #555;
}

.checkbox-section .agreement {
  width: 100%; 
  line-height: 1.7rem;
  font-size: 14px; 
  text-align: left; 
  margin-bottom: 10px;
}

.checkbox-section label {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: #444;
  gap: 8px;
}

.checkbox-section input {
  margin-right: 5px;
  width: 33px;
  height: 33px;
  border-radius: 3px;
  border: 1px solid #ccc;
  background-color: #fff;
  transition: all 0.3s ease;
}
.checkbox-section input:checked {
  background-color: #007bff;
  border-color: #007bff;
  background-image: url('data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 fill=%22none%22 viewBox=%220 0 16 16%22%3E%3Cpath fill=%22%23fff%22 d=%22M13.293 4.707a1 1 0 0 0-1.414 0L6 10.586 3.707 8.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l7-7a1 1 0 0 0 0-1.414z%22/%3E%3C/svg%3E');
  background-size: 12px;
  background-position: center;
  background-repeat: no-repeat;
}

.checkbox-section a {
  color: #007bff;
  text-decoration: none;
}

.checkbox-section a:hover {
  text-decoration: underline;
}

/* Form buttons */

.next-button {
  padding: 12px 20px;
  font-size: 15px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  background-color: #007bff;
  color: #fff;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.next-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.next-button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
.agreement a {
  color: #007bff; /* Or whatever color you prefer */
  text-decoration: none; /* Remove underline */
  display: inline;

}

.agreement a:hover {
  text-decoration: underline; /* Add underline on hover */
}

/* Media query for smaller screens (1024px and below) */
@media (max-width: 1024px) {
  .contact-form {
    padding: 20px;
    width: 90%;
    margin: 30px auto;
  }

  h3 {
    font-size: 28px; /* Slightly smaller title font size */
  }

  .form-group input {
    width: 100%;
    margin: 0px;
  }

  .bluetooth-dot {
    width: 8px;
    height: 8px;
  }

  .form-group {
    gap: 10px; /* Slightly reduce gap between input fields */
  }

  .form-group label {
    margin-bottom: 0px;
  }

 

  .next-button {
    width: 90%;  /* Full-width button on smaller screens */
    font-size: 16px;
  }
 
}

/* Media query for mobile devices (768px and below) */
@media (max-width: 768px) {
  .contact-form {
    padding: 20px;
  }

  h3 {
    font-size: 22px; /* Smaller font size for very small screens */
  }

  .bluetooth-dot {
    width: 8px;
    height: 8px;
  }

  .form-group {
    gap: 8px; /* Tighten gap for very small screens */
  }


  .next-button {
    width: 90%; /* Full-width button on mobile */
    font-size: 16px;
  }
}
