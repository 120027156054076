#addpost{
    display: flex;
    flex-direction: column;
}
.addpost{
  padding-top: 120px;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
#addpoststay{
    position: relative;
    width: 80%;
    padding: 0%;
}
#addpoststay .form-group textarea{
    width: 95%;
    height: 100px;
    margin: 0%;
}
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 65px;
  height: auto;
}

/* spinner */
.spiner{
    width: 100%;
    height: 70vh;
    align-items: center;
    justify-content: center;
    display: flex;

}
/* search div */
.search-container {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  width: 100%;
  height: 30vh;
  align-items: center;
  background-image: url("../../images/city3.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.search-container input[type="text"] {
  width: 40%;
  height: 50px;
  padding-left: 10px;
border-top: 6px solid #1100ff;
border-bottom: 6px solid rgb(26, 102, 241);
border-right: 6px solid rgb(26, 102, 241);
border-left: 6px solid rgb(26, 102, 241);
}

.search-container button {
  background-color: rgb(26, 102, 241);
  border: none;
  border-radius: 0 5px 5px 0;
  color: white;
  font-weight: bold;
  cursor: pointer;
  width: 30%;
  height: 60px;
  text-align: center;
}

button:hover {
  background-color: #395ac9;
}
/* contract advert */
.contractAd p{
    color:rgb(26, 102, 241);
    font-family:"Roboto";
    font-style: italic;
}

/* post items and advert */
.postcontainer{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: space-evenly;
}
.middle {
    width: 50%;
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
}

 .left, .right {
  width: 22%;
  text-align: center;
  color: aliceblue;
  justify-content: flex-start;
  display: flex;
  flex-direction: column; 
    position:sticky;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

}
/* postCategory */
.postCategory{
    background-color: #395bc99c;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    padding-left: 10px;
    font-size: larger;
    font-weight: 500;
    margin-bottom: 35px;
}

.postTitle{
    width: 90%;
    height: 50px;
    background-color: #1100ff;
    border-radius: 10px;
    align-items: center;
    display: flex;
    color: white;
    font-size: larger;
    font-weight: 500;
    padding-left: 10px;
    display: flex;
    justify-self:center;
}
.postTitle h1{
   padding:10px ;
   font-weight: 500;
}
.postContent{
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
}
.postContent p{
    padding: 10px;
}
.postContent img{
    width: 90%;
    height:45vh;
}

/* share post */
.sharepost {
margin:20px 0px ;
}

.sharepost p{
font-style: italic;
color: #1100ff;
padding: 0%;
margin: 10px;
font-weight: 500;
font-size: large;
}
#related{
    font-style: italic;
    margin-bottom: 10px;
}
.relateditems{
     width: 90%;
    background-color: rgba(233, 233, 233, 0.671);
    border-radius: 5px;
    display: flex;
    padding-left: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding:  5px;
    flex-direction: row;
    align-items: center;
}
 .releteditemtext h4{
    color: #1100ff;
    font-size: larger;
    font-weight: 700;
    margin: 0px;
}
.relateditems img{
    width: 40%;
    height: 100px;
    float: right;
}
.postmaintitle h1{
    font-weight: 600;
    font-size:1.7em;
}
.modal {
    width: 30%;
    padding: 10px;
  background-color: rgba(0, 0, 0, 0.822);
 position: absolute;
    background-color: white;
  align-self: center;
  border-radius: 10px;
   margin-top: 50px;
   align-items: center;
   justify-content: center;
   display: flex;
   flex-direction: column;
   z-index: 3;
}


@media (max-width: 768px) {
 .releteditemtext h4{
    color: #1100ff;
    margin-top: 1px;
    margin-bottom: 1px;
}
.releteditemtext p{
    margin-top: 0%;
    line-height: 1em;
}
.relateditems img{
    width: 40%;
    height: 80%;
    float: right;
}
  .postcontainer{
  display: flex;
  flex-direction:column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
  .right {
  width: 95%;
  align-items: center;
  position: relative;
}
.left{
    position: relative;
    width: 100%;
}
.leftad{
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.middle {
    width: 90%;
    padding: 0%;
}
  .postCategory{
    margin-bottom: 0px;
    width: 90%;
  }
  .postContent img{
    width: 95%;
    height:45vh;
}
.postTitle{
    height: 35px;
}
.container {
  padding-top: 55px;
}
.modal {
    width: 70%;

}
}
