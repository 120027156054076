.aboutConatainer{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.aboutus{
    width: 60%;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: #395ac9;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 190px 0px;
}
.aboutus h1{
font-size: 30px;
margin-bottom: 5px;
}
.aboutus p{
    width:  70%;
    line-height: 1.7rem;
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;

}
.aboutservice{
    background-color: #1100ff;
    color: white;
}
.aboutservice h1{
    text-align: center;
    font-size: 30px;
margin-bottom: 0px;
}
.aboutservice ol{
   width: 100%;
   align-items: center;
   justify-content: center;
   display: flex;
   flex-direction: column;
}
.aboutservice ol li{
    list-style:circle;
    padding: 6px;
}
@media (max-width: 720px) {
    .aboutus{
        width: 100%;
    }
    .aboutservice ol li{
    padding: 0px;
}
.aboutservice ol li{
    list-style:circle;
    padding: 0px;
    width: 90%;
}
.aboutservice ol{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

}