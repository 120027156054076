
/* Contact Ribbon Styles */
.contact-ribbon {
    background-color: black;
    color: white;
    text-align: center;
    padding: 10px 30px;
    font-size: 14px;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* Keeps it on top */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px; /* Add space between icons and text */
}

.contact-ribbon a {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 5px; /* Space between icon and text */
    font-size: 12px;
    font-weight: 300;
    transition: color 0.3s ease;
}

.contact-ribbon a:hover {
    color: #1877F2; /* Hover effect for clickable links */
}

.contact-icon {
    font-size: 16px;
    color: white;
    cursor: pointer;
    transition: color 0.3s ease;
}

.contact-icon:hover {
    color: #25D366; /* WhatsApp green on hover */
}


/* navbar */
.header-maincontainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    margin-top: 30px;
    flex-direction: row;
    position: relative; /* Default position */
    width: 100%;
    z-index: 10; /* Ensure it stays on top */
    top: 0;
    height: 80px;
}
/* Add this class for when the user scrolls */
.header-maincontainer.scrolled {
    margin-top: 0px; 
    position: fixed; 
    top: 0; 
    background-color: white;
}
.header-maincontainer .logo {
    width: 100px;
    height: 100px;
    z-index: 1; 
}

.header-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 80%;
    transition: all 0.3s ease; /* Smooth transition for showing/hiding */
}

.header-links .link-text {
    list-style: none;
    font-size: small;
    font-weight: 700;
    color: black;
    position: relative;
}

.header-links .link-text::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 0%;
    height: 4px;
    background-color: blue;
    transition: width 0.3s ease;
}

.header-links .link-text:hover::after {
    width: 100%;
}

.link-menu {
    display: none; /* Hide the menu icon by default */
    cursor: pointer;
}


/* Container for the slider */
.slider {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    position: relative;
    text-align: center;
    margin-top: 100px;
    padding: 0px 30px;
}
.slider-contentent-wrapper{
    padding-bottom: 35vh;
    width: 100%;
}
/* Title and description section */
.slider-h1-container, .slider-button-container{
    display: block;
    unicode-bidi: isolate;
}
.slider-paragraph-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.slider-h1-container h2 {
    font-size: 6.5vh;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 7.1vh;
    margin: 0 0 8px;
    text-align: center;
}
.slider-paragraph-container p{
    color: #777; 
    font-size: 18px;
    font-weight: 400; 
    line-height: 27px; 
    max-width: 600px;
}
.colored-dot{
    display: inline-block;
    width: 15px;  /* Adjust size as needed */
    height: 15px;
    background-color: #007BFF; 
    border-radius: 50%; 
    margin-left: 3px;
}

.slider-button-container a{
    align-items: center;
    background-color: #007BFF;
    border: 0 solid transparent;
    border-radius: 5px 5px 5px 5px;
    color: #fff;
    display: inline-flex;
    font-family: Montserrat, sans-serif;
    font-size: 1.4vh;
    font-weight: 900;
    justify-content: center;
    letter-spacing: 2px;
    line-height: 5.4vh;
    margin-top: 50px;
    max-width: 397px;
    min-height: 5.4vh;
    min-width: 231px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    z-index: 1;
}

/* Image styling */
.slider-left-image {
    bottom: 6.4vh;
    left: 29.5%;
    position: absolute;
    transform: translateX(-50%);
}
.slider-left-image img{
    max-width: 85.9vh;
    transform: matrix(.984808, .173648, -.173648, .984808, 0, 0);
    transform-origin: 50% 50% 0;
    vertical-align: middle;
    height: auto;
}

.slider-right-image{
    bottom: 14vh;
    position: absolute;
    right: 31%;
    transform: translateX(50%);
}
.slider-right-image img{
    max-width: 65.4vh;
    vertical-align: middle;
    height: auto;
}


/* counter area  */

.counter-area{
    width: 100%;
    height: auto;
    display:flex;
    align-self:center ;
    background-color: #afaaaa27;
    padding: 0px 30px;
    align-items: center;
    justify-content: center;
}
.process-container{
    width: 100%;
    height: auto;
    display:flex;
    align-self:center ;
    padding: 0px 30px;
    align-items: center;
    justify-content: center;
}
/* Modal  */
.modal{
    width: 100%;
    background-color: red;
}

/* Responsive design for smaller screens */
/* Sticky Navbar for Big Screens */
@media (min-width: 1024px) {
    .header-maincontainer {
        position: fixed; /* Make navbar sticky */
        top: 0; /* Stick to the top */
        left: 0;
        right: 0;
        background-color: white; /* Optional: Background color for navbar */
        z-index: 999; /* Ensure it's always on top of other elements */
    }
    
}

/* Mobile & Tablet View */
@media (max-width: 1024px) {
    .header-maincontainer {
        flex-direction: row-reverse;
        margin: 0px;
    }
    .contact-ribbon {
        display: none;
    }

    .header-links {
        position: fixed;
        top: 80px; /* Below the logo */
        left: 0;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        background-color: white;
        height: calc(100vh - 100px); /* Full height minus logo */
        z-index: 0;
        transform: translateY(-100%);
        opacity: 0;
        transition: transform 0.3s ease, opacity 0.3s ease;
        will-change: transform, opacity;
    }

    /* Show the menu icon */
    .link-menu {
        display: block;
        z-index: 2;
        font-size: 20px;
    }

    /* When menu is open */
    .header-links.open {
        transform: translateY(0);
        opacity: 1;
        background-color: white;
        z-index: 5;
        transition: transform 0.3s ease, opacity 0.3s ease, z-index 0s 0s; /* z-index activates immediately */
    }

    .header-links .link-text {
        font-size: 15px; /* Larger font for mobile */
        margin-bottom: 40px; /* Add space between links */
    }

    /* slider */
    .slider-contentent-wrapper{
        padding-bottom: 60vh;
    }
    .slider-h1-container h2 {
        font-size: 2.5vh;  /* Reduce font size for tablet and smaller screens */
        line-height: 3vh;  /* Adjust line height for readability */
        margin: 0px;  /* Slightly reduce the bottom margin */
        letter-spacing: -0.5px;
    }
    .colored-dot{
        width: 7px;  
        height: 7px;
    }
    .slider-left-image {
        bottom: 4.4vh;
    }
 .slider-right-image img{
        display: none;
    }
    .slider-left-image {
        left: 50.5%;
    }
}

/* Large Desktop / High-Resolution Screens */
@media (min-width: 1440px) {
    .header-links {
        width: 50%;
    }
}

/* footer */
.footercontainer{
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.contactIcons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    padding: 10px;
}
.flogo{
    width: 70px;
    height: 60px;
    border-radius: 10px;
    align-items: center;
}
.cicons{
    width: 20%;
    justify-content: space-evenly;
    display: flex;
    flex-direction: row;
}
.cicons .contact-item{
    background-color: antiquewhite;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: 10px;
}
.line{
    width: 95%;
    height: 0.6px;
    background-color: black;
    align-self: center;
    opacity: 0.3;
}
 .cicons p {
    color: black;
    font-style: italic;
    font-weight: lighter;
        opacity: 0.8;

}
.contactIcons p a{
    color: black;
    font-style: italic;
    font-weight: lighter;
    opacity: 0.8;
    text-align: center;

}
.cicons p a:hover{
    text-decoration: underline;
}
.contactIcons p a:hover{
    text-decoration: underline;
}



/* terms of services */
.terms h1{
    text-align: center;
    font-size: 30px;
margin-bottom: 0px;
}
.terms{
  padding: 40px;
    background-color: #1100ff;
    color: white;
}
.terms h1{
   width: 100%;
   align-items: center;
   justify-content: center;
   display: flex;
   flex-direction: column;
}
.terms ol li{
    list-style:circle;
    padding: 6px;
}


/* spinner */
.spinner{
    width: 200px;
    height: 200px;
    align-self: center;
    z-index: 2;
    position: absolute;
    display: flex;
    justify-content: center;
}
@media (max-width: 720px) {
  .terms{
  padding:43px 5px;

}
.terms ol li{
    padding: 0px;
    width: 90%;
}
    .form-group input, textarea{
         width: 300px;
    height: 40px;
    margin: 10px;
    }
    .bottomContainer{
    display: flex;
    flex-direction: column;
}
.show{
    margin-left: 200px;
}
.logo{
    margin-left: 10px;
}
.menuicon{
    margin-left: 210px;
}
.Explore{
    display: flex;
    flex-direction: column;
}
.new {
    display: none;
}
.bottitle{
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
}
.arrowdown{
    width: 0px;
    height: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid black;
    margin: 2rem;
}
.arrowup{
    width: 0px;
    height: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid black;
    margin: 2rem;
}
.notNew a{
    color: black;
    font-size: normal;
    font-weight: 400;
    margin-bottom:4px ;
    display: flex;
    flex-direction: column;
    padding: 3px;
    margin-left: 5px;
}
.notNew p{
    color: black;
    font-size: normal;
    font-weight: 400;
    margin-bottom:4px ;
    display: flex;
    flex-direction: column;
    padding: 3px;
    margin-left: 5px;
}
.contactIcons{
    display: flex;
    flex-direction: column;
   
}
.cicons{
 width: 60%;
 align-self: center;
}
.flogo{
    display: none;
}
}


