.counter-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 60px 20px;
  width: 80%;
  flex-wrap: wrap;
}

.counter-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}

.counter {
  font-size: 45px;
  font-weight: bold;
  color: #2c3e50;
  transition: transform 0.2s ease-in-out;
}

.digit, .char {
  font-size: inherit;
  color: inherit;
}

.counter-title {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
    color: #7f8c8d;

}

/* Responsive Design */
@media (max-width: 1024px) {
  .counter-section {
    display: flex;
    flex-direction: column;
  }
}

/* Optional Animation for the counter values (you can implement a number animation script later) */
@keyframes countUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.counter-item {
  animation: countUp 0.5s ease-in-out;
}
