/* Set the background color to black */
.company-logos-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 logos per row */
  grid-template-rows: repeat(2, auto); /* 2 rows */
  gap: 30px; /* Increased gap for more breathing space */
  justify-items: center;
  align-items: center;
  padding: 40px;
  background-color: #000; /* Black background */
  min-height: 50vh; /* Allow the container to grow as needed */
  margin-top: 40px;
  margin-bottom: 10px;
  border-bottom-right-radius: 15px; 
  border-bottom-left-radius: 15px; 
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3); /* Soft shadow around the container */
  overflow: hidden; /* Prevents any overflow outside the container */
}

/* Style the logo images */
.company-logo {
  max-width: 150px;
  max-height: 100px;
  object-fit: contain;
  transition: transform 0.3s ease, box-shadow 0.3s ease, filter 0.3s ease; /* Smooth transitions */
  filter: brightness(0.6) invert(1); /* Makes logos with black text/graphics visible on black */
  border-radius: 10px; /* Slight rounded corners for logos */
}

/* Add hover effect for a more interactive feel */
.company-logo:hover {
  transform: scale(1.1);
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.3); /* Soft shadow with a larger spread */
  filter: brightness(1) invert(1); /* Makes logos more visible on hover */
}

/* Media query for tablets */
@media (max-width: 768px) {
  .company-logo {
    max-width: 120px;
    max-height: 80px;
  }

  .company-logos-container {
    grid-template-columns: repeat(3, 1fr); /* 3 logos per row on tablets */
    gap: 20px; /* Reduce gap size for smaller screens */
    padding: 30px; /* Reduce padding for smaller screens */
  }
}

/* Media query for mobile devices */
@media (max-width: 480px) {
  .company-logo {
    max-width: 100px;
    max-height: 60px;
  }

  .company-logos-container {
    grid-template-columns: repeat(2, 1fr); /* 2 logos per row on mobile */
    gap: 15px; /* Reduce gap size for mobile */
    padding: 20px; /* Further reduce padding for mobile */
  }
}
