

/* Style the button on hover */
#trans:hover {
  background-color: rgb(35, 83, 185);
}
.second{
    width: 100%;
    background-color: #1100ff;
    display: flex;
    flex-direction: column;
}
.serviceRender{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 60px;
    margin-bottom: 40px;
    position: relative;
}
.cardtext{
    width: 50%;
    color:white;
    font-size: 20px;
  line-height: 2;
}
.car{
    padding: 60px;
}
.learnimage{
height: 280px;
width: 43%;
 border-radius: 10px;
 box-shadow: 0.5px 0px 1px 1px black;
 transition: 0.3s;
 float: right;
}
#tex{
    text-align: left;
    float: left;
}
.happyClient{
    background-color: white;
    width: 100%;
    text-align: center;
}
.happyClient h1{
 color: #395ac9;
    font-weight: bold;
    font-style: normal;
    font-size: 35px;
}
.unique{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
}
.lasttest{
    width: 100%;
    padding: 40px 0;
    background-color: #1100ff; 
    color: white;  
    align-items: center;    
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.lasttest h1{
    font-weight: 600;
    font-size: 25px;
    align-self: center;
    width: 50%;
    text-align: center;
}
.lasttest p{
    width: 70%;
    line-height: 1.5;
    font-size: 24px;
    text-align: left;
    align-self: center;
}
@media (max-width: 720px) {
   .lasttest h1{
    width: 65%;
   }
   .car{
    padding: 10px;
}
    .landingContent {
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .landingContent h1{
        font-size: 30px;
        font-weight: 700;
    }
    .landingBTN{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    margin-top: 30px;
}
#trans{
margin-top: 20px;
}
.serviceRender{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}
.cardtext{
    width: 90%;
    color:white;
    
}
.learnimage{
height: 250px;
width: 90%;
}

}