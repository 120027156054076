/* Set a background color for the body */
*{
  overflow-x: hidden;
  overflow-y: auto;
  verflow-y: scroll; /* Allow vertical scrolling */
  scrollbar-width: none; 
}
*, *:before, *:after {
  box-sizing: border-box;
 
}

body {
  margin: 0;
  padding:0px;
  font-family: -apple-system, Montserrat, sans-serif,
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: hidden;
  overflow-y: auto;
}
body::-webkit-scrollbar{
    display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


/* Style the header */
header {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* removes all styles from links*/
a{
    text-decoration: none;
}



/* Style the heading on the landing page */
h1 {
  font-size: 48px;
}

/* Style the alert */

.alert {
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.alert-success {
  background-color: #1dcf23;
}

.alert-error {
  background-color: #ff0606;
}

.alert-info {
  background-color: #ffee00c4;
}
.alertclose{
    width: 60px;
    height: 30px;
    border-radius: 10px;
    color: #1100ff;
    align-self: center;
    margin-top: 10px;
}



